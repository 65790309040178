"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.applyPublishingProfileToSettings = void 0;

var tslib_1 = require("tslib");
/**
 * Apply a publishing profile to the base settings, resulting in a "ready to run" version of the settings
 * for the new adaptive runtime. This function transforms the profile settings format to match the new
 * Adaptive Runtime settings form.
 * @param settings
 * @param profile
 * @returns
 */


exports.applyPublishingProfileToSettings = function (settings, profile) {
  var _a, _b, _c, _d, _e; // ensure the runtimeSettings key exists


  settings.runtimeSettings = settings.runtimeSettings || {}; // apply the application insights resource from the publish profile
  // to the telemetry setting

  if (((_a = profile.settings.applicationInsights) === null || _a === void 0 ? void 0 : _a.InstrumentationKey) || ((_b = profile.settings.applicationInsights) === null || _b === void 0 ? void 0 : _b.connectionString)) {
    settings.runtimeSettings.telemetry = tslib_1.__assign(tslib_1.__assign({}, settings.runtimeSettings.telemetry), {
      options: {
        connectionString: profile.settings.applicationInsights.connectionString,
        instrumentationKey: profile.settings.applicationInsights.InstrumentationKey
      }
    });
  } // apply the blob storage resource from the publish profile
  // to the blobTranscript feature option


  if ((_c = profile.settings.blobStorage) === null || _c === void 0 ? void 0 : _c.connectionString) {
    settings.runtimeSettings.features = tslib_1.__assign(tslib_1.__assign({}, settings.runtimeSettings.features), {
      blobTranscript: {
        connectionString: profile.settings.blobStorage.connectionString,
        containerName: profile.settings.blobStorage.container
      },
      setSpeak: tslib_1.__assign({}, (_d = settings.runtimeSettings.features) === null || _d === void 0 ? void 0 : _d.setSpeak)
    });
  } // apply the cosmosDb resource to the storage setting


  if ((_e = profile.settings.cosmosDb) === null || _e === void 0 ? void 0 : _e.authKey) {
    settings.runtimeSettings.storage = 'CosmosDbPartitionedStorage';
    settings.CosmosDbPartitionedStorage = tslib_1.__assign({}, profile.settings.cosmosDb);
  } // apply LUIS settings


  if (profile.settings.luis) {
    settings.luis = tslib_1.__assign(tslib_1.__assign({}, settings.luis), profile.settings.luis);
  } // apply QNA settings


  if (profile.settings.qna) {
    settings.qna = tslib_1.__assign(tslib_1.__assign({}, settings.qna), profile.settings.qna);
  } // apply the app id and password


  settings.MicrosoftAppId = profile.settings.MicrosoftAppId;
  settings.MicrosoftAppPassword = profile.settings.MicrosoftAppPassword; // apply the SCM domain host

  settings.scmHostDomain = profile.scmHostDomain;
  return settings;
};