"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.indexer = void 0;

var tslib_1 = require("tslib");

var recognizerIndexer_1 = require("./recognizerIndexer");

var dialogIndexer_1 = require("./dialogIndexer");

var dialogSchemaIndexer_1 = require("./dialogSchemaIndexer");

var jsonSchemaFileIndexer_1 = require("./jsonSchemaFileIndexer");

var skillManifestIndexer_1 = require("./skillManifestIndexer");

var botProjectSpaceIndexer_1 = require("./botProjectSpaceIndexer");

var fileExtensions_1 = require("./utils/fileExtensions");

var help_1 = require("./utils/help");

var formDialogSchemaIndexer_1 = require("./formDialogSchemaIndexer");

var crossTrainConfigIndexer_1 = require("./crossTrainConfigIndexer");

var Indexer =
/** @class */
function () {
  function Indexer() {
    this.separateDialogsAndRecognizers = function (files) {
      return files.reduce(function (result, file) {
        if (file.name.endsWith('.lu.dialog') || file.name.endsWith('.qna.dialog')) {
          result.recognizers.push(file);
        } else {
          result.dialogs.push(file);
        }

        return result;
      }, {
        dialogs: [],
        recognizers: []
      });
    };

    this.separateConfigAndManifests = function (files) {
      return files.reduce(function (result, file) {
        if (file.name.endsWith('.config.json')) {
          result.crossTrainConfigs.push(file);
        } else {
          result.skillManifestFiles.push(file);
        }

        return result;
      }, {
        crossTrainConfigs: [],
        skillManifestFiles: []
      });
    };

    this.getResources = function (files, extension) {
      return files.map(function (_a) {
        var name = _a.name,
            content = _a.content;
        return {
          id: help_1.getBaseName(name, extension),
          content: content
        };
      });
    };
  }

  Indexer.prototype.classifyFile = function (files) {
    var _a;

    return files.reduce(function (result, file) {
      var extension = help_1.getExtension(file.name);

      if (extension && result[extension]) {
        result[extension].push(file);
      }

      return result;
    }, (_a = {}, _a[fileExtensions_1.FileExtensions.lg] = [], _a[fileExtensions_1.FileExtensions.Lu] = [], _a[fileExtensions_1.FileExtensions.FormDialogSchema] = [], _a[fileExtensions_1.FileExtensions.QnA] = [], _a[fileExtensions_1.FileExtensions.Dialog] = [], _a[fileExtensions_1.FileExtensions.DialogSchema] = [], _a[fileExtensions_1.FileExtensions.Manifest] = [], _a[fileExtensions_1.FileExtensions.BotProjectSpace] = [], _a[fileExtensions_1.FileExtensions.CrossTrainConfig] = [], _a));
  };

  Indexer.prototype.index = function (files, botName) {
    var result = this.classifyFile(files);

    var _a = this.separateDialogsAndRecognizers(result[fileExtensions_1.FileExtensions.Dialog]),
        dialogs = _a.dialogs,
        recognizers = _a.recognizers;

    var _b = this.separateConfigAndManifests(result[fileExtensions_1.FileExtensions.Manifest]),
        skillManifestFiles = _b.skillManifestFiles,
        crossTrainConfigs = _b.crossTrainConfigs;

    var assets = {
      dialogs: dialogIndexer_1.dialogIndexer.index(dialogs, botName),
      dialogSchemas: dialogSchemaIndexer_1.dialogSchemaIndexer.index(result[fileExtensions_1.FileExtensions.DialogSchema]),
      lgResources: this.getResources(result[fileExtensions_1.FileExtensions.lg], fileExtensions_1.FileExtensions.lg),
      luResources: this.getResources(result[fileExtensions_1.FileExtensions.Lu], fileExtensions_1.FileExtensions.Lu),
      qnaResources: this.getResources(result[fileExtensions_1.FileExtensions.QnA], fileExtensions_1.FileExtensions.QnA),
      skillManifests: skillManifestIndexer_1.skillManifestIndexer.index(skillManifestFiles),
      botProjectSpaceFiles: botProjectSpaceIndexer_1.botProjectSpaceIndexer.index(result[fileExtensions_1.FileExtensions.BotProjectSpace]),
      jsonSchemaFiles: jsonSchemaFileIndexer_1.jsonSchemaFileIndexer.index(result[fileExtensions_1.FileExtensions.Json]),
      formDialogSchemas: formDialogSchemaIndexer_1.formDialogSchemaIndexer.index(result[fileExtensions_1.FileExtensions.FormDialogSchema]),
      recognizers: recognizerIndexer_1.recognizerIndexer.index(recognizers),
      crossTrainConfig: crossTrainConfigIndexer_1.crossTrainConfigIndexer.index(crossTrainConfigs)
    };
    return tslib_1.__assign({}, assets);
  };

  return Indexer;
}();

exports.indexer = new Indexer();

tslib_1.__exportStar(require("./botIndexer"), exports);

tslib_1.__exportStar(require("./dialogIndexer"), exports);

tslib_1.__exportStar(require("./dialogSchemaIndexer"), exports);

tslib_1.__exportStar(require("./lgIndexer"), exports);

tslib_1.__exportStar(require("./luIndexer"), exports);

tslib_1.__exportStar(require("./qnaIndexer"), exports);

tslib_1.__exportStar(require("./utils"), exports);

tslib_1.__exportStar(require("./validations"), exports);

tslib_1.__exportStar(require("./skillIndexer"), exports);

tslib_1.__exportStar(require("./botProjectSpaceIndexer"), exports);

tslib_1.__exportStar(require("./extractSchemaProperties"), exports);

tslib_1.__exportStar(require("./groupTriggers"), exports);